<template>
  <main class="w-100 xl:grid xl:grid-cols-2 h-screen">
    <section class="w-100 col-span-1 h-screen">
      <div class="w-5/6 sm:w-3/6 mx-auto pt-32">
        <div class="mb-12">
          <h1
            class="text-primary text-5xl font-semibold leading-loose uppercase"
          >
            Hello<span class="text-red-300 text-5xl">,</span>
          </h1>
        </div>
        <h1 class="text-4xl mb-10">Log in</h1>
        <div class="">
          <div class="space-y-1 mb-4">
            <h3 class="text-xl">Email</h3>
            <input
              type="email"
              class="
                bg-gray-200
                border-b border-white
                px-6
                w-full
                py-3
                font-medium
                focus:outline-none focus:border-primary
                rounded
              "
              v-model="email"
              placeholder="e.g markelves@company.com "
            />
          </div>
          <div class="space-y-1 mb-4">
            <h3 class="text-xl">Password</h3>
            <div class="relative">
              <input
                :type="type"
                class="
                  bg-gray-200
                  border-b border-white
                  px-6
                  w-full
                  py-3
                  font-medium
                  focus:outline-none focus:border-primary
                  rounded
                "
                placeholder="password"
                v-model="password"
              />
              <div
                class="absolute right-6 bottom-3 cursor-pointer"
                @click="changeType()"
              >
                <view-icon :width="'w-6'" :height="'h-6'"></view-icon>
              </div>
            </div>
          </div>
          <div class="mt-8">
            <button
              class="
                block
                bg-black
                w-full
                py-3
                text-center text-xl text-white
                rounded
              "
              @click="signIn()"
            >
              Log in
            </button>
            <div class="text-center my-2 text-xl">
              Do not have an account?
              <router-link to="/register" class="underline font-bold"
                >Sign Up</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="
        w-100
        col-span-1
        shadow-2xl
        bg-opacity-50
        py-28
        px-28
        hidden
        xl:block
      "
    >
      <loginsvg></loginsvg>
    </section>
    <div
      class="fixed left-0 top-0 w-full bg-black bg-opacity-60 h-screen"
      v-if="loading"
    >
      <div class="absolute top-2/4 w-full">
        <loading :width="'w-10'" :height="'h-10'"></loading>
      </div>
    </div>
  </main>
</template>

<script>
import Loginsvg from '../components/Icons/Loginsvg.vue'
import ViewIcon from '../components/Icons/viewIcon.vue'
import Swal from 'sweetalert2'
import Loading from '../components/Icons/Loading.vue'
export default {
  components: { Loginsvg, ViewIcon, Loading },
  data() {
    return {
      email: '',
      password: '',
      visible: false,
      type: 'password',
      loading: false
    }
  },
  methods: {
    changeType() {
      if (this.type == 'text') {
        this.type = 'password'
        this.visible = true
      } else {
        this.type = 'text'
        this.visible = false
      }
    },
    signIn() {
      if (this.validator()) {
        this.loading = true
        var user = {
          email: this.email,
          password: this.password
        }
      }
      this.$store.dispatch('logIn', user)
    },
    validator() {
      if (this.email != '' && this.password != '') {
        return true
      } else {
        Swal.fire('Info', 'Fill all details', 'info', 1500)
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('middleware/isLogged')
  }
}
</script>

<style>
.lead-hero-text {
  font-family: 'Comfortaa', cursive;
}
</style>
