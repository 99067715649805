<template>
  <div>
    <svg
      class="animated"
      id="freepik_stories-medicine"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
    >
      <g
        id="freepik--background-simple--inject-78"
        class="animable"
        style="transform-origin: 249.042px 249.91px"
      >
        <path
          d="M417.69,51.84c-30.45-18.94-66.95-21-99.11-5.23C291.19,60,269.88,82.77,242.46,96.17,205,114.5,163.87,124.52,126,142.08s-74.12,45.08-87.7,84.56c-16,46.63-10.38,101.46,14.09,144.09,39.78,69.29,118.68,93.9,194.64,93,160.51-1.89,243.19-196.29,217-336C458.31,97.46,444.37,68.43,417.69,51.84Z"
          style="fill: #1b2cc1; transform-origin: 249.042px 249.91px"
          id="el8wicc9z65s"
          class="animable"
        ></path>
        <g id="elf3pqrbp9w1g">
          <path
            d="M417.69,51.84c-30.45-18.94-66.95-21-99.11-5.23C291.19,60,269.88,82.77,242.46,96.17,205,114.5,163.87,124.52,126,142.08s-74.12,45.08-87.7,84.56c-16,46.63-10.38,101.46,14.09,144.09,39.78,69.29,118.68,93.9,194.64,93,160.51-1.89,243.19-196.29,217-336C458.31,97.46,444.37,68.43,417.69,51.84Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.7;
              transform-origin: 249.042px 249.91px;
            "
            class="animable"
            id="eljrieaecbm6g"
          ></path>
        </g>
      </g>
      <g
        id="freepik--Graphics--inject-78"
        class="animable"
        style="transform-origin: 245.915px 235.06px"
      >
        <polygon
          points="120.61 310.27 112.21 311.79 110.56 302.69 100.58 304.5 102.23 313.6 93.84 315.12 95.71 325.45 104.1 323.93 105.75 333.03 115.73 331.23 114.08 322.12 122.48 320.6 120.61 310.27"
          style="
            fill: none;
            stroke: #1b2cc1;
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 108.16px 317.86px;
          "
          id="el17gce1yoexs"
          class="animable"
        ></polygon>
        <polygon
          points="204.47 170.87 196.42 168.03 199.5 159.31 189.94 155.93 186.86 164.65 178.81 161.81 175.32 171.71 183.36 174.55 180.28 183.28 189.84 186.65 192.93 177.93 200.97 180.77 204.47 170.87"
          style="
            fill: none;
            stroke: #1b2cc1;
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 189.895px 171.29px;
          "
          id="eljyxe297fop"
          class="animable"
        ></polygon>
        <polygon
          points="407.51 275.09 399.97 279.08 395.64 270.9 386.67 275.64 391 283.82 383.46 287.81 388.37 297.09 395.91 293.1 400.24 301.27 409.21 296.53 404.88 288.35 412.42 284.36 407.51 275.09"
          style="
            fill: none;
            stroke: #1b2cc1;
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 397.94px 286.085px;
          "
          id="eliqwyjntx4ik"
          class="animable"
        ></polygon>
        <circle
          cx="85.53"
          cy="282.47"
          r="6.12"
          style="
            fill: none;
            stroke: #1b2cc1;
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 85.53px 282.47px;
          "
          id="el23fv2tc9qxc"
          class="animable"
        ></circle>
        <path
          d="M180.86,200.65a1.62,1.62,0,1,1-1.62-1.62A1.62,1.62,0,0,1,180.86,200.65Z"
          style="
            fill: none;
            stroke: #1b2cc1;
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 179.24px 200.65px;
          "
          id="eluh3zxyg4rgo"
          class="animable"
        ></path>
        <path
          d="M106.73,172.86a5.72,5.72,0,1,1-5.72-5.72A5.73,5.73,0,0,1,106.73,172.86Z"
          style="
            fill: none;
            stroke: #1b2cc1;
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 101.01px 172.86px;
          "
          id="elcqt65zczrk7"
          class="animable"
        ></path>
        <path
          d="M358.76,250.34a3.24,3.24,0,1,1-3.24-3.24A3.24,3.24,0,0,1,358.76,250.34Z"
          style="
            fill: none;
            stroke: #1b2cc1;
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 355.52px 250.34px;
          "
          id="elcu083iabcno"
          class="animable"
        ></path>
        <circle
          cx="326.01"
          cy="144.34"
          r="7.25"
          style="
            fill: none;
            stroke: #1b2cc1;
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 326.01px 144.34px;
          "
          id="eljbwiq7z9qh"
          class="animable"
        ></circle>
      </g>
      <g
        id="freepik--Character--inject-78"
        class="animable"
        style="transform-origin: 253.315px 262.322px"
      >
        <path
          d="M281.37,209.51c.38.57,11.85,2.86,17.8,11.51a82.52,82.52,0,0,1,8.66,15.91s9.95,5.92,15.05,10.8,11.18,11.36,15.87,14.76,14.34,9.85,16.77,17.4c0,0-14.47,39.63-18.56,48.39s-14.18,27.35-14.18,27.35l-18,76.47H200.37l-3.56-26.23s-32-6.28-45.37-18c0,0-1.26-29,4.33-54.87s24.6-79.66,37.48-96.09c0,0,20.57-9.84,38.72-13,0,0,11.78-9.31,19.49-10.3S281.37,209.51,281.37,209.51Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 253.419px 320.805px;
          "
          id="elq78u1t058a"
          class="animable"
        ></path>
        <path
          d="M198.22,417.69c9.27,1.77,17.35,3.3,26.79,3.19s19.08-2.23,26.8-7.64a17.23,17.23,0,0,1,5-2.9c4.1-1.15,8.16,1.56,12.23,2.82,5.16,1.6,10.82.84,15.88-1.08s9.61-4.91,14.12-7.89q-1,13.8-.65,27.65l6.43.26,16.47-72.47s-28.17,32.72-41.9,42.49l-26.34-6.78s-2.48,2.06-7.7,3.1c0,0-2.39,9.19-15.83,9.21s-32.71-1.78-32.71-1.78Z"
          style="fill: #1b2cc1; transform-origin: 259.05px 395.865px"
          id="elmbax5adw5p"
          class="animable"
        ></path>
        <g id="elf3l0fmc1jt9">
          <path
            d="M198.22,417.69c9.27,1.77,17.35,3.3,26.79,3.19s19.08-2.23,26.8-7.64a17.23,17.23,0,0,1,5-2.9c4.1-1.15,8.16,1.56,12.23,2.82,5.16,1.6,10.82.84,15.88-1.08s9.61-4.91,14.12-7.89q-1,13.8-.65,27.65l6.43.26,16.47-72.47s-28.17,32.72-41.9,42.49l-26.34-6.78s-2.48,2.06-7.7,3.1c0,0-2.39,9.19-15.83,9.21s-32.71-1.78-32.71-1.78Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.5;
              transform-origin: 259.05px 395.865px;
            "
            class="animable"
            id="el5v7wrkcf3rn"
          ></path>
        </g>
        <path
          d="M171.23,282.47S159,341.84,159.62,362.23c0,0,16.62-4.66,28.22,0,0,0-19.74-2.2-28.24,6.85s17.66,32.83,70,28c0,0,10.44,3.27,15.74,1.35,0,0-1.42,9.46-15.83,9.21-14.35-.57-56.33-.92-78.08-19.76C151.44,387.89,148.7,333.31,171.23,282.47Z"
          style="fill: #1b2cc1; transform-origin: 198.335px 345.057px"
          id="eliw8ft3ltgw9"
          class="animable"
        ></path>
        <g id="eli57r3vr5fs">
          <path
            d="M171.23,282.47S159,341.84,159.62,362.23c0,0,16.62-4.66,28.22,0,0,0-19.74-2.2-28.24,6.85s17.66,32.83,70,28c0,0,10.44,3.27,15.74,1.35,0,0-1.42,9.46-15.83,9.21-14.35-.57-56.33-.92-78.08-19.76C151.44,387.89,148.7,333.31,171.23,282.47Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.6;
              transform-origin: 198.335px 345.057px;
            "
            class="animable"
            id="ellik78v7j2gc"
          ></path>
        </g>
        <path
          d="M196.24,293.85s3.61,26.29,9.44,31.79,33,3.7,43.76,9.63c0,0,10.13-17.55,28.45-28.53l-24.84,39.11-21.47-5.07-26.21-4-2.2,5.06-3.94,0S198.79,313.78,196.24,293.85Z"
          style="fill: #1b2cc1; transform-origin: 237.065px 319.85px"
          id="elx0alqznw1wc"
          class="animable"
        ></path>
        <g id="el47080t7yrmi">
          <path
            d="M196.24,293.85s3.61,26.29,9.44,31.79,33,3.7,43.76,9.63c0,0,10.13-17.55,28.45-28.53l-24.84,39.11-21.47-5.07-26.21-4-2.2,5.06-3.94,0S198.79,313.78,196.24,293.85Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.5;
              transform-origin: 237.065px 319.85px;
            "
            class="animable"
            id="elns2dcru67ae"
          ></path>
        </g>
        <path
          d="M298.93,240.3c11.23,6.61,12.49,14.06,14.8,19.38-5.84,1.16-11.88,2.4-16.7,5.89-6,4.36-9.25,11.58-11.77,18.59a185.24,185.24,0,0,0-6,20.26,69.61,69.61,0,0,1,22.49-24.34c5.39-3.56,12.05-6.43,18.2-4.42-5.16,2.19-11,5.15-12.09,10.65a5.17,5.17,0,0,0,.11,2.76c.92,2.59,4.14,3.29,6.58,4.55,4.76,2.45,7.24,8,7.45,13.38s-1.44,10.58-3.07,15.67q-2.15,6.69-4.3,13.38c-1.83,5.7-3.69,11.48-7,16.48s-8.28,9.19-14.19,10.17a12.35,12.35,0,0,1-7.33-.83,24.32,24.32,0,0,1-4.83-3.45,50,50,0,0,0-24.64-10.64c11.62,7.68,17.12,21.63,21.94,34.7a25.55,25.55,0,0,0-9.25-9.36,5.91,5.91,0,0,0-3.36-1.06c-3.16.25-4.16,4.28-6.06,6.8-2.28,3-6.45,4.07-10.19,3.59s-7.18-2.23-10.54-4l-42.51-21.92-3,9s33.86,27,85.74,36.55c0,0,33.7-26.63,45.64-50.24s30.49-72,30.49-72-6.79-12.86-18.43-19.57c0,0-8.69-4.11-10.52-9.19s-18.16-13.85-18.16-13.85S310.55,242.65,298.93,240.3Z"
          style="fill: #1b2cc1; transform-origin: 274.605px 319.655px"
          id="elcmtf5lwtnna"
          class="animable"
        ></path>
        <g id="elr7osccd26cg">
          <path
            d="M298.93,240.3c11.23,6.61,12.49,14.06,14.8,19.38-5.84,1.16-11.88,2.4-16.7,5.89-6,4.36-9.25,11.58-11.77,18.59a185.24,185.24,0,0,0-6,20.26,69.61,69.61,0,0,1,22.49-24.34c5.39-3.56,12.05-6.43,18.2-4.42-5.16,2.19-11,5.15-12.09,10.65a5.17,5.17,0,0,0,.11,2.76c.92,2.59,4.14,3.29,6.58,4.55,4.76,2.45,7.24,8,7.45,13.38s-1.44,10.58-3.07,15.67q-2.15,6.69-4.3,13.38c-1.83,5.7-3.69,11.48-7,16.48s-8.28,9.19-14.19,10.17a12.35,12.35,0,0,1-7.33-.83,24.32,24.32,0,0,1-4.83-3.45,50,50,0,0,0-24.64-10.64c11.62,7.68,17.12,21.63,21.94,34.7a25.55,25.55,0,0,0-9.25-9.36,5.91,5.91,0,0,0-3.36-1.06c-3.16.25-4.16,4.28-6.06,6.8-2.28,3-6.45,4.07-10.19,3.59s-7.18-2.23-10.54-4l-42.51-21.92-3,9s33.86,27,85.74,36.55c0,0,33.7-26.63,45.64-50.24s30.49-72,30.49-72-6.79-12.86-18.43-19.57c0,0-8.69-4.11-10.52-9.19s-18.16-13.85-18.16-13.85S310.55,242.65,298.93,240.3Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.6;
              transform-origin: 274.605px 319.655px;
            "
            class="animable"
            id="el6lxv116cadd"
          ></path>
        </g>
        <path
          d="M295.36,148.25s2.27-26.47-9.14-29-17.82-13-17.82-13l-40.48,11.3-6.28,24.34,4,12.32s.84,11.43,1,17.08,0,10,4.28,16.86,8.26,13.67,17.51,19.6v15.59s-5.72,16.69-2.56,26.93c0,0,1.89,2,7.11-1s27.55-17.92,35.34-33.5l2.42-39.61s6.43,1,9.2-4.86c4.07-5.73,3.91-12.41,2.33-17.85s-8.38-1.92-8.38-1.92Z"
          style="
            fill: rgb(153, 153, 153);
            transform-origin: 262.472px 178.587px;
          "
          id="elauxt0hfhhv5"
          class="animable"
        ></path>
        <path
          d="M248.87,187.06a11.27,11.27,0,0,1,9.08-3.62s4.87-7.69,10-6.28c0,0,1.58,8.15-4.57,11.36C255.38,192.69,248.87,187.06,248.87,187.06Z"
          style="
            fill: rgb(115, 115, 115);
            transform-origin: 258.501px 183.523px;
          "
          id="eld6ykn2mhn8e"
          class="animable"
        ></path>
        <path
          d="M233.83,114.18s-9.69,12.73-9.13,26,2.49,22.68,1.93,32.92,10.24,27.39,21.31,34.3c8.27,5.17,18.64,4.29,26.69-1,9-6,13.95-16.78,15.76-27.13.18-1,.32-2,.42-3.08"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 257.743px 162.517px;
          "
          id="elxfr6cmedyle"
          class="animable"
        ></path>
        <path
          d="M247.94,207.41s1.57,14.34,0,19.25c-1.73,5.39-3.82,17.94-2,23.61s35.87-18.16,42.45-34.51c0,0,.91-31.77,3.18-39.71"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 268.403px 213.587px;
          "
          id="elgbbcg56e4f"
          class="animable"
        ></path>
        <path
          d="M247,186.71s15.49,4.38,21.83-11.61"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 257.915px 181.179px;
          "
          id="eljc1a107ogja"
          class="animable"
        ></path>
        <path
          d="M248.25,210.86s3.3,10.23,10.42,10.84,24.93-13.66,31-30.47v-8.41s-5,22-19.64,26-21.49-1.09-21.49-1.09Z"
          style="fill: rgb(38, 50, 56); transform-origin: 268.96px 202.269px"
          id="elkluj2fwl4f"
          class="animable"
        ></path>
        <path
          d="M267.94,177.16c-6.88-.48-10,6.28-10,6.28a10.18,10.18,0,0,0-9.08,3.62,12.3,12.3,0,0,0,12.69,2.3C269.37,186.64,267.94,177.16,267.94,177.16Z"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 258.451px 183.67px;
          "
          id="el0n62agh606lk"
          class="animable"
        ></path>
        <path
          d="M250.69,147a66.94,66.94,0,0,1-.42,9.5c-.37,2.83-1.68,5.54-2.77,8.2-1,2.42-2.67,6.14-1.28,8.69,1.58,2.89,6.83,0,6.83,0"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 249.37px 160.837px;
          "
          id="elzy9qcr554cl"
          class="animable"
        ></path>
        <path
          d="M256.28,172.79c1.77.24,4.28,0,4.78-3.07"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 258.67px 171.29px;
          "
          id="el8ybeoyjposn"
          class="animable"
        ></path>
        <path
          d="M262,143.18a9.2,9.2,0,0,1,12.7,0"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 268.35px 141.909px;
          "
          id="el8efenwv1ayh"
          class="animable"
        ></path>
        <path
          d="M241.82,145.68s-4.69-6-10.83,0"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 236.405px 144.347px;
          "
          id="el8rfyxsa1xxo"
          class="animable"
        ></path>
        <path
          d="M287.13,160.85s5.35-12.85,12.33-10.09c4.81,1.91,5.1,10.21,2.55,16s-3.83,11.69-12.33,8.39"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 295.365px 163.236px;
          "
          id="eldwap6g8rs2o"
          class="animable"
        ></path>
        <path
          d="M288.08,158.88s-5.14-1.82-6.09-10.17S286.16,132,284.26,126s-16.87-3-20.28-13.46c0,0-11.24,8.38-33,6.58,0,0-4.59,7.82-5.39,12.6a77.47,77.47,0,0,0,.08,22.53c0,.26.09.51.14.77,1.56,8.14-6.21-11.08-6-19.29s-4.74-18.64,1.71-26.41S251.62,97,256.28,93.35,270,99.42,280.47,103s19.15,7.85,17.83,22.7.07,16.84.13,24.66C298.43,150.38,293.59,148.56,288.08,158.88Z"
          style="fill: rgb(38, 50, 56); transform-origin: 258.313px 125.712px"
          id="elss1cbfv3gca"
          class="animable"
        ></path>
        <path
          d="M268.37,154c0,2-.77,3.66-1.73,3.66s-1.72-1.64-1.72-3.66.77-3.65,1.72-3.65S268.37,152,268.37,154Z"
          style="fill: rgb(38, 50, 56); transform-origin: 266.645px 154.005px"
          id="elqt2d9zapvx9"
          class="animable"
        ></path>
        <path
          d="M241.82,154c0,2-.77,3.66-1.72,3.66s-1.72-1.64-1.72-3.66.77-3.65,1.72-3.65S241.82,152,241.82,154Z"
          style="fill: rgb(38, 50, 56); transform-origin: 240.1px 154.005px"
          id="elyzyx1ivwuxn"
          class="animable"
        ></path>
        <path
          d="M262,158.88s3.57,3.79,8.09,4.33"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 266.045px 161.045px;
          "
          id="el98gszd5jlam"
          class="animable"
        ></path>
        <path
          d="M237.68,163.21s6.53-.46,8-4.33"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 241.68px 161.045px;
          "
          id="el5vx4hdgp17q"
          class="animable"
        ></path>
        <path
          d="M252.86,252.7c4.86-3.09,11.29-1.93,16.61.26,2.92,1.21,5.7,2.73,8.61,4a31.67,31.67,0,0,0,4.44,1.58c3.65.95,2.34-3.58,2.13-5.61-.3-2.91-.51-5.83-.86-8.72a2.63,2.63,0,0,1,.2-1.78,2.48,2.48,0,0,1,1.42-.87c6.41-2,13.32-1.12,20-1a3,3,0,0,0,1.76-.33c1-.65.89-2.12.62-3.28-2.31-9.88-10.46-19.71-19.32-24.65"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 280.418px 235.485px;
          "
          id="elkzcyxgk3gz9"
          class="animable"
        ></path>
        <path
          d="M248.5,213.9c-4.41,1.84-8.94,3.75-12.74,6.57a24.57,24.57,0,0,0-3.79,3.42,5.34,5.34,0,0,0-1.63,3.31,3.94,3.94,0,0,0,1.59,3,10.14,10.14,0,0,0,2.2,1.38c1.84.87,4,1.72,4.78,3.57a2.17,2.17,0,0,1,.2,1.25,2.3,2.3,0,0,1-.56,1c-1.52,1.85-4.2,3.28-4,5.67.13,1.85,2,3,3.66,3.82s3.6,1.67,4.17,3.44"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 239.42px 232.115px;
          "
          id="el4lxx31fk89h"
          class="animable"
        ></path>
        <path
          d="M222.39,120.13c.65-4,4.83-6.58,8.85-7.23s8.15,0,12.2-.35a23.78,23.78,0,0,0,17.06-9.91"
          style="
            fill: none;
            stroke: rgb(255, 255, 255);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 241.445px 111.385px;
          "
          id="elyq9jstlkkhs"
          class="animable"
        ></path>
        <path
          d="M226.94,108.89c7.26-4.29,17.46.17,24.54-4.41a22.13,22.13,0,0,0,5.16-5.28"
          style="
            fill: none;
            stroke: rgb(255, 255, 255);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 241.79px 104.045px;
          "
          id="elshvvsdw51hj"
          class="animable"
        ></path>
        <path
          d="M319.55,273c-26,5.19-35.7,24.32-35.7,24.32l-30.8,48.51"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 286.3px 309.415px;
          "
          id="elsqinrszp9ki"
          class="animable"
        ></path>
        <path
          d="M297.57,267.76a29.53,29.53,0,0,1,7.25-4.94"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 301.195px 265.29px;
          "
          id="ellygtkhypye"
          class="animable"
        ></path>
        <path
          d="M279.39,304.38s3.28-17.52,12.7-30.43"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 285.74px 289.165px;
          "
          id="elkk855t8qjx"
          class="animable"
        ></path>
        <path
          d="M199.23,341.76s-1.35-53.67-6-61.87"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 196.23px 310.825px;
          "
          id="els0mcvxsq5y9"
          class="animable"
        ></path>
        <path
          d="M307.83,236.93s15,9,21.66,17.46,22.4,14.08,26,25.5c0,0-20.73,65.32-42.45,88.93s-33.68,33.3-33.68,33.3-52.25-9.08-86.14-37.54l12.12-27.85s34.17,4.32,47.68,9.12,26.34,33.87,26.34,33.87"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 274.355px 319.525px;
          "
          id="el3ubcu9ren5k"
          class="animable"
        ></path>
        <path
          d="M253.05,345.85s23.92,4.65,31.69,15.54"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 268.895px 353.62px;
          "
          id="elx30cdiqxrq"
          class="animable"
        ></path>
        <polyline
          points="321.88 357.05 304.82 432.1 200.37 432.1 196.81 405.87"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 259.345px 394.575px;
          "
          id="elj9kq43lck5"
          class="animable"
        ></polyline>
        <path
          d="M245.35,398.44c-5.77,11.82-13.38,9.21-13.38,9.21-58.85,2.23-80.53-19.76-80.53-19.76s-3-30.93,8.57-72.67,33.24-78.29,33.24-78.29c9.61-6.39,31.05-11.38,37.1-12.7l1.62-.34"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 198.22px 315.892px;
          "
          id="elbam2zwruzy"
          class="animable"
        ></path>
        <path
          d="M232,407.65s-3.11-.51-2.32-21.13"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 230.776px 397.085px;
          "
          id="el3laljkzc6yu"
          class="animable"
        ></path>
        <path
          d="M194.84,365.9s-14.7-5.18-32.91,7.42"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 178.385px 369.083px;
          "
          id="elh4l9ix95rqf"
          class="animable"
        ></path>
        <path
          d="M165.23,361.39s19.26-5.73,28,3.45"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 179.23px 362.232px;
          "
          id="el6f4434xuyy3"
          class="animable"
        ></path>
        <path
          d="M191.15,364.32l2.5,1.25,9.52-23.78s-9.86-1.61-25.48,17.84A22.41,22.41,0,0,1,191.15,364.32Z"
          style="
            fill: rgb(153, 153, 153);
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 190.43px 353.664px;
          "
          id="eljxlwpvu6md"
          class="animable"
        ></path>
        <path
          d="M229.65,386.52l0,10.57s13.47,4,23.44-1.75Z"
          style="
            fill: rgb(153, 153, 153);
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 241.37px 392.482px;
          "
          id="ell3cjeiepl7"
          class="animable"
        ></path>
        <path
          d="M302.66,240.45a1.91,1.91,0,0,1-.06.58c-.42,1.65-10.69,40.42-43.56,57.67a1.94,1.94,0,0,1-.93.23,2,2,0,0,1-.93-3.77c30-15.73,40.57-51.58,41.49-54.88Z"
          style="fill: rgb(38, 50, 56); transform-origin: 279.387px 269.605px"
          id="el64dmdgfdc73"
          class="animable"
        ></path>
        <path
          d="M231.93,230.17a98.49,98.49,0,0,0-8.25,37.6c0,1.05,0,2.12,0,3.21,0,.27,0,.54,0,.81a6.71,6.71,0,0,0-.89.09,10.61,10.61,0,0,0-3.11,1c0-1.47,0-2.9,0-4.31.43-22.44,7.13-37.71,10.7-44.31l1.62-.34a5.34,5.34,0,0,0-1.63,3.31A3.94,3.94,0,0,0,231.93,230.17Z"
          style="fill: rgb(38, 50, 56); transform-origin: 225.84px 248.4px"
          id="eltw5k4n5tpaj"
          class="animable"
        ></path>
        <path
          d="M271.31,289.68c2.34,3.25.42,8.65-4.3,12.06s-10.44,3.53-12.79.28-.43-8.65,4.29-12.06S269,286.43,271.31,289.68Z"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 262.762px 295.85px;
          "
          id="elz9a2qvxwzjg"
          class="animable"
        ></path>
        <path
          d="M265.49,287.75c1.66,2.3.3,6.12-3,8.53s-7.39,2.5-9,.2-.3-6.12,3-8.53S263.83,285.46,265.49,287.75Z"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 259.501px 292.117px;
          "
          id="el590eox4b035"
          class="animable"
        ></path>
        <path
          d="M239.06,328.34a2,2,0,0,1-2.39-3.2c.17-.14,6.88-5.85,3.94-25.08-.25-1.63-.57-3.37-1-5.2-1.27-5.83-3.23-13.21-7.24-18.63a10,10,0,0,0-8.75-4.44,6.71,6.71,0,0,0-.89.09,10.61,10.61,0,0,0-3.11,1,12.91,12.91,0,0,0-6.32,7.38l-.36,1a146.35,146.35,0,0,0-5.55,18.79c-1.6,7.85-2.14,16.08.7,21.83a12.5,12.5,0,0,0,7.76,6.57,2,2,0,0,1-.62,3.9,2,2,0,0,1-.62-.1,16.5,16.5,0,0,1-10.1-8.6c-3.2-6.47-2.78-15.28-1.16-23.6a140.36,140.36,0,0,1,5.82-20.12l.36-1a16.54,16.54,0,0,1,10.09-10.37,14.17,14.17,0,0,1,2.51-.62c.51-.07,1-.12,1.52-.15a14,14,0,0,1,11.94,6.09c4.46,6,6.58,13.93,7.93,20.15.47,2.14.83,4.15,1.1,6.05C247.78,321.64,239.45,328.06,239.06,328.34Z"
          style="
            fill: rgb(38, 50, 56);
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 223.723px 300.067px;
          "
          id="ell5ixeg1r108"
          class="animable"
        ></path>
        <path
          d="M213.83,332s7.25,3,9.09,0,.35-4.41-2.16-4.65a73.46,73.46,0,0,0-7.51,0Z"
          style="
            fill: rgb(38, 50, 56);
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 218.495px 330.294px;
          "
          id="elu7tol0aplb"
          class="animable"
        ></path>
        <path
          d="M217.16,331a2,2,0,0,1-1.9,1.38,2,2,0,0,1-.62-.1,16.5,16.5,0,0,1-10.1-8.6c-3.2-6.47-2.78-15.28-1.16-23.6h4c-1.6,7.85-2.14,16.08.7,21.83a12.5,12.5,0,0,0,7.76,6.57A2,2,0,0,1,217.16,331Z"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 209.698px 316.23px;
          "
          id="elea3eammolgu"
          class="animable"
        ></path>
        <path
          d="M232.38,326.17a3.33,3.33,0,0,0-.75,2.61,3.05,3.05,0,0,0,3.24,2.18,5.74,5.74,0,0,0,3.64-2c1-1.12,4.47-8.45,1.26-7.19C237.44,322.65,233.94,324.14,232.38,326.17Z"
          style="
            fill: rgb(38, 50, 56);
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 236.35px 326.3px;
          "
          id="elxlj7vsft3v"
          class="animable"
        ></path>
        <path
          d="M239.06,328.34a2,2,0,0,1-2.39-3.2c.17-.14,6.88-5.85,3.94-25.08h4C247.78,321.64,239.45,328.06,239.06,328.34Z"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 240.64px 314.367px;
          "
          id="elj1anxcrzwa"
          class="animable"
        ></path>
        <g id="elxkyxhpog1h">
          <path
            d="M257,151.59s12.59-6.54,24.16,0c0,0,.19,9.65-4.18,13.62-1.55,1.41-8.65,1.65-11.34,1.41C254.72,165.63,257,151.59,257,151.59Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.2;
              transform-origin: 268.982px 157.692px;
            "
            class="animable"
            id="elxkstf47kxr"
          ></path>
        </g>
        <g id="elhaqqp16zd9u">
          <path
            d="M249.1,151.59s-12.59-6.54-24.15,0c0,0-.19,9.65,4.17,13.62,1.55,1.41,8.66,1.65,11.34,1.41C251.38,165.63,249.1,151.59,249.1,151.59Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.2;
              transform-origin: 237.123px 157.692px;
            "
            class="animable"
            id="elywubluo71ac"
          ></path>
        </g>
        <path
          d="M257,151.59s12.59-6.54,24.16,0c0,0,.19,9.65-4.18,13.62-1.55,1.41-8.65,1.65-11.34,1.41C254.72,165.63,257,151.59,257,151.59Z"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 268.982px 157.692px;
          "
          id="eljzqe9y3hj7"
          class="animable"
        ></path>
        <path
          d="M249.1,151.59s-12.59-6.54-24.15,0c0,0-.19,9.65,4.17,13.62,1.55,1.41,8.66,1.65,11.34,1.41C251.38,165.63,249.1,151.59,249.1,151.59Z"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 237.123px 157.692px;
          "
          id="elelhh8chxuco"
          class="animable"
        ></path>
        <path
          d="M249.1,151.59s2.82-2.07,7.9,0"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 253.05px 151.13px;
          "
          id="el2eoehs7bk8f"
          class="animable"
        ></path>
        <line
          x1="281.16"
          y1="151.59"
          x2="286.42"
          y2="154.03"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 283.79px 152.81px;
          "
          id="elizns452sow9"
          class="animable"
        ></line>
      </g>
      <g
        id="freepik--Table--inject-78"
        class="animable animator-hidden"
        style="transform-origin: 256.315px 445.945px"
      >
        <polyline
          points="79.82 458.72 116.83 423.76 410.57 423.76 432.81 458.72 79.82 458.72"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 256.315px 441.24px;
          "
          id="elf8cqjfnprkf"
          class="animable"
        ></polyline>
        <rect
          x="79.82"
          y="458.72"
          width="352.99"
          height="9.41"
          style="
            fill: rgb(38, 50, 56);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 256.315px 463.425px;
          "
          id="els5xf45c4b8"
          class="animable"
        ></rect>
      </g>
      <g
        id="freepik--Notebook--inject-78"
        class="animable animator-hidden"
        style="transform-origin: 156.82px 435.485px"
      >
        <polygon
          points="116.83 450.08 116.83 442.83 174.83 442.83 174.83 450.08 163.64 450.08 116.83 450.08"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 145.83px 446.455px;
          "
          id="eltssu8jux80h"
          class="animable"
        ></polygon>
        <polygon
          points="116.83 442.83 151.09 419.85 196.81 420.93 174.83 442.83 116.83 442.83"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 156.82px 431.34px;
          "
          id="eli81c9oar33g"
          class="animable"
        ></polygon>
        <polygon
          points="174.83 450.08 196.81 428.06 196.81 420.93 174.83 442.83 174.83 450.08"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 185.82px 435.505px;
          "
          id="elw28jx50l4bj"
          class="animable"
        ></polygon>
        <path
          d="M173.26,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 170.945px 446.06px;
          "
          id="elpe2zb41i3om"
          class="animable"
        ></path>
        <path
          d="M168.63,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 166.315px 446.06px;
          "
          id="elthzm1mee87g"
          class="animable"
        ></path>
        <path
          d="M164,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 161.685px 446.06px;
          "
          id="el8rufr1zubx6"
          class="animable"
        ></path>
        <path
          d="M159.37,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 157.055px 446.06px;
          "
          id="el8yitlpxoi6v"
          class="animable"
        ></path>
        <path
          d="M154.74,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 152.425px 446.06px;
          "
          id="elvdvljqy53mk"
          class="animable"
        ></path>
        <path
          d="M150.11,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 147.795px 446.06px;
          "
          id="el9lem0nkxqq5"
          class="animable"
        ></path>
        <path
          d="M145.48,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 143.165px 446.06px;
          "
          id="el9luib53bnyw"
          class="animable"
        ></path>
        <path
          d="M140.85,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 138.535px 446.06px;
          "
          id="elm35ntrsijjs"
          class="animable"
        ></path>
        <path
          d="M135.65,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 133.335px 446.06px;
          "
          id="elspne4r24e2c"
          class="animable"
        ></path>
        <path
          d="M130.44,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 128.125px 446.06px;
          "
          id="elru98xywqbkc"
          class="animable"
        ></path>
        <path
          d="M124.65,441s-4.63.92-4.63,4.93,4,5.19,4,5.19"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            transform-origin: 122.335px 446.06px;
          "
          id="elyblybo6m51"
          class="animable"
        ></path>
        <path
          d="M154,425.48a16.75,16.75,0,0,0-7.21,7.85C151,432,154.33,429,157.52,426l-3.46,8.58a10.17,10.17,0,0,0,7.84-4.81,2.12,2.12,0,0,0-1,3c2.9-.42,4.75-3.22,6.21-5.76a18.68,18.68,0,0,1,.31,4.29,13.59,13.59,0,0,0,7.16-.91"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 0.75px;
            transform-origin: 160.685px 430.03px;
          "
          id="el4genm0bua32"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Box--inject-78"
        class="animable animator-hidden"
        style="transform-origin: 349.905px 408.705px"
      >
        <rect
          x="307.8"
          y="388.74"
          width="100.81"
          height="55.35"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 358.205px 416.415px;
          "
          id="elr4nfw0rdwx"
          class="animable"
        ></rect>
        <polygon
          points="307.41 444.08 291.2 428.67 291.2 373.32 307.41 388.74 307.41 444.08"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 299.305px 408.7px;
          "
          id="elc8qt5llegcs"
          class="animable"
        ></polygon>
        <polygon
          points="408.61 388.74 392.4 373.32 291.6 373.32 307.8 388.74 408.61 388.74"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 350.105px 381.03px;
          "
          id="elkkz23unjl6i"
          class="animable"
        ></polygon>
        <polygon
          points="371.54 409.5 362.63 409.5 362.63 400.18 350.07 400.18 350.07 409.5 340.35 409.5 340.35 422.06 350.07 422.06 350.07 431.37 362.63 431.37 362.63 422.06 371.54 422.06 371.54 409.5"
          style="
            fill: #1b2cc1;
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 355.945px 415.775px;
          "
          id="eln4exfugfxf"
          class="animable"
        ></polygon>
        <line
          x1="357.33"
          y1="388.74"
          x2="341.12"
          y2="373.32"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 349.225px 381.03px;
          "
          id="el0u2lebbl08ki"
          class="animable"
        ></line>
      </g>
      <g
        id="freepik--Medicine--inject-78"
        class="animable animator-hidden"
        style="transform-origin: 253.855px 140.225px"
      >
        <g id="eldhyncdna6j">
          <circle
            cx="122.96"
            cy="132.2"
            r="30.11"
            style="
              fill: rgb(255, 255, 255);
              stroke: rgb(38, 50, 56);
              stroke-linecap: round;
              stroke-linejoin: round;
              transform-origin: 122.96px 132.2px;
              transform: rotate(-45deg);
            "
            class="animable"
            id="ely4duqr7kv9e"
          ></circle>
        </g>
        <path
          d="M148,127.59a9.64,9.64,0,0,1-6.55,9.13L128,141.25l-16.37,5.53a9.62,9.62,0,0,1-6.15-18.23l14.87-5,14.94-5a9.63,9.63,0,0,1,12.7,9.09Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 123.503px 132.654px;
          "
          id="elz6cjs6jsye"
          class="animable"
        ></path>
        <path
          d="M148,127.59a9.64,9.64,0,0,1-6.55,9.13L128,141.25a27.85,27.85,0,0,0-7.65-17.71l14.94-5a9.63,9.63,0,0,1,12.7,9.09Z"
          style="fill: #1b2cc1; transform-origin: 134.175px 129.644px"
          id="el5elfqy75xwk"
          class="animable"
        ></path>
        <path
          d="M148,127.59a9.64,9.64,0,0,1-6.55,9.13L128,141.25l-16.37,5.53a9.62,9.62,0,0,1-6.15-18.23l14.87-5,14.94-5a9.63,9.63,0,0,1,12.7,9.09Z"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 123.503px 132.654px;
          "
          id="elpr3r9bb884"
          class="animable"
        ></path>
        <path
          d="M128,141.25a27.26,27.26,0,0,0-7.65-17.71"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 124.175px 132.395px;
          "
          id="elsz8bzu60xzi"
          class="animable"
        ></path>
        <path
          d="M144.78,124a6.58,6.58,0,0,1,.92,3.52"
          style="
            fill: none;
            stroke: rgb(255, 255, 255);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 145.241px 125.76px;
          "
          id="ells1mn0hhg1h"
          class="animable"
        ></path>
        <path
          d="M124.15,125l12-4a7.7,7.7,0,0,1,6.54.73"
          style="
            fill: none;
            stroke: rgb(255, 255, 255);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 133.42px 122.792px;
          "
          id="elrljretfzvrd"
          class="animable"
        ></path>
        <g id="elyczpoh0twan">
          <circle
            cx="315.81"
            cy="63.61"
            r="30.11"
            style="
              fill: rgb(255, 255, 255);
              stroke: rgb(38, 50, 56);
              stroke-linecap: round;
              stroke-linejoin: round;
              transform-origin: 315.81px 63.61px;
              transform: rotate(-22.94deg);
            "
            class="animable"
            id="elo4gf68dcv2o"
          ></circle>
        </g>
        <path
          d="M326.19,76.23l-26.26-8.79a8.69,8.69,0,0,1-5.47-11h0a8.69,8.69,0,0,1,11-5.47l26.26,8.78a8.7,8.7,0,0,1,5.47,11h0A8.7,8.7,0,0,1,326.19,76.23Z"
          style="fill: #1b2cc1; transform-origin: 315.824px 63.5982px"
          id="elnhumq00qh0b"
          class="animable"
        ></path>
        <line
          x1="306.95"
          y1="66.74"
          x2="311.35"
          y2="56.23"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 309.15px 61.485px;
          "
          id="elk5m7mkhe3f9"
          class="animable"
        ></line>
        <line
          x1="321.42"
          y1="71.55"
          x2="325.8"
          y2="61.07"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 323.61px 66.31px;
          "
          id="elkvzhcgolkwm"
          class="animable"
        ></line>
        <circle
          cx="310.58"
          cy="66.47"
          r="0.89"
          style="fill: rgb(38, 50, 56); transform-origin: 310.58px 66.47px"
          id="elds5r59dyhga"
          class="animable"
        ></circle>
        <path
          d="M319.33,69.09a.89.89,0,1,1-.89-.89A.89.89,0,0,1,319.33,69.09Z"
          style="fill: rgb(38, 50, 56); transform-origin: 318.44px 69.09px"
          id="elbr28j42ytde"
          class="animable"
        ></path>
        <circle
          cx="314.03"
          cy="58.55"
          r="0.89"
          style="fill: rgb(38, 50, 56); transform-origin: 314.03px 58.55px"
          id="elr3fhehff5kn"
          class="animable"
        ></circle>
        <path
          d="M322.65,61.09a.89.89,0,1,1-.89-.89A.89.89,0,0,1,322.65,61.09Z"
          style="fill: rgb(38, 50, 56); transform-origin: 321.76px 61.09px"
          id="elew3bf0jg5pp"
          class="animable"
        ></path>
        <path
          d="M326.19,76.23l-26.26-8.79a8.69,8.69,0,0,1-5.47-11h0a8.69,8.69,0,0,1,11-5.47l26.26,8.78a8.7,8.7,0,0,1,5.47,11h0A8.7,8.7,0,0,1,326.19,76.23Z"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 315.824px 63.5982px;
          "
          id="el67ys2y4orio"
          class="animable"
        ></path>
        <circle
          cx="202.14"
          cy="61.99"
          r="30.11"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 202.14px 61.99px;
          "
          id="el4csyod6flnh"
          class="animable"
        ></circle>
        <path
          d="M219.39,62.76V75.1c0,3.13-2,5.67-4.45,5.67h-25.6c-2.46,0-4.45-2.54-4.45-5.67V62.76c0-3.13,2-5.67,4.45-5.67h4.21V49.93h17v7.16h4.36C217.39,57.09,219.39,59.63,219.39,62.76Z"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 202.14px 65.35px;
          "
          id="elkppwys3k4ue"
          class="animable"
        ></path>
        <rect
          x="184.89"
          y="64.18"
          width="34.49"
          height="7.65"
          style="
            fill: #1b2cc1;
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 202.135px 68.005px;
          "
          id="el70mlmbyvmpd"
          class="animable"
        ></rect>
        <path
          d="M195.9,41.55h12.64a5.38,5.38,0,0,1,5.38,5.38v5.59a0,0,0,0,1,0,0H190.51a0,0,0,0,1,0,0V46.93A5.38,5.38,0,0,1,195.9,41.55Z"
          style="
            fill: #1b2cc1;
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 202.215px 47.035px;
          "
          id="el33fuu4ixaq9"
          class="animable"
        ></path>
        <line
          x1="194.6"
          y1="41.89"
          x2="194.6"
          y2="52.52"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 194.6px 47.205px;
          "
          id="els9dox4z9ro"
          class="animable"
        ></line>
        <line
          x1="200.13"
          y1="41.55"
          x2="200.13"
          y2="52.52"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 200.13px 47.035px;
          "
          id="elbnokrrx8b1b"
          class="animable"
        ></line>
        <line
          x1="205.66"
          y1="41.55"
          x2="205.66"
          y2="52.52"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 205.66px 47.035px;
          "
          id="elthgru0xc3j"
          class="animable"
        ></line>
        <line
          x1="211.19"
          y1="42.17"
          x2="211.19"
          y2="52.52"
          style="
            fill: none;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 211.19px 47.345px;
          "
          id="el7ef4s04r5qn"
          class="animable"
        ></line>
        <g id="el5ia66q8r3f7">
          <circle
            cx="95.29"
            cy="221.72"
            r="30.11"
            style="
              fill: rgb(255, 255, 255);
              stroke: rgb(38, 50, 56);
              stroke-linecap: round;
              stroke-linejoin: round;
              transform-origin: 95.29px 221.72px;
              transform: rotate(-80.69deg);
            "
            class="animable"
            id="el0uzdd1pmwyyq"
          ></circle>
        </g>
        <g id="el4ddz9oerg4v">
          <ellipse
            cx="94.55"
            cy="221.92"
            rx="13.89"
            ry="17.71"
            style="
              fill: #1b2cc1;
              stroke: rgb(38, 50, 56);
              stroke-linecap: round;
              stroke-linejoin: round;
              transform-origin: 94.55px 221.92px;
              transform: rotate(-64.69deg);
            "
            class="animable"
            id="elo3wbfpay0bp"
          ></ellipse>
        </g>
        <g id="elb7eicxf0ww">
          <ellipse
            cx="95.64"
            cy="219.55"
            rx="13.03"
            ry="17.71"
            style="
              fill: #1b2cc1;
              stroke: rgb(38, 50, 56);
              stroke-linecap: round;
              stroke-linejoin: round;
              transform-origin: 95.64px 219.55px;
              transform: rotate(-64.69deg);
            "
            class="animable"
            id="el3d0haaa2erq"
          ></ellipse>
        </g>
        <line
          x1="100.75"
          y1="210.66"
          x2="89.82"
          y2="226.03"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 95.285px 218.345px;
          "
          id="elx25ru5tub5b"
          class="animable"
        ></line>
        <path
          d="M94.17,208.17a23.58,23.58,0,0,1,4.31,1"
          style="
            fill: none;
            stroke: rgb(255, 255, 255);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 96.325px 208.67px;
          "
          id="el99zsdylglqg"
          class="animable"
        ></path>
        <path
          d="M84.46,222.64s-5.58-6.11-.13-12A8.34,8.34,0,0,1,89,208.24"
          style="
            fill: none;
            stroke: rgb(255, 255, 255);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 85.4705px 215.44px;
          "
          id="elzzcjwx8hki7"
          class="animable"
        ></path>
        <g id="elow1oseagaih">
          <circle
            cx="412.42"
            cy="221.72"
            r="30.11"
            style="
              fill: rgb(255, 255, 255);
              stroke: rgb(38, 50, 56);
              stroke-linecap: round;
              stroke-linejoin: round;
              transform-origin: 412.42px 221.72px;
              transform: rotate(-80.69deg);
            "
            class="animable"
            id="el6iipsdmyxxn"
          ></circle>
        </g>
        <path
          d="M429.73,224.76c-.23.33-.46.67-.7,1-4.51,6.25-11.46,12.08-16.61,13.23v-.08c-5-1.12-11.73-6.67-16.24-12.73-.25-.33-.49-.67-.72-1-4.34-6.22-6.17-12.75-1.34-15.45,9.82-5.48,18.3,6.27,18.3,6.27s8.5-11.66,18.3-6.19C435.42,212.43,433.81,218.69,429.73,224.76Z"
          style="
            fill: #1b2cc1;
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 412.42px 223.632px;
          "
          id="el9w99k4dz0kp"
          class="animable"
        ></path>
        <path
          d="M429.73,224.76c-.23.33-.46.67-.7,1h-2.48l-2.84,3.75-1.93-11.12-3,7.56h-7.09L408.06,232l-2.8-12.25-3.61,6.39h-5.47c-.25-.33-.49-.67-.72-1h5.61l4.59-8.13,2.82,12.35,2.67-4.45h7l4-10.24,2.14,12.36,1.75-2.31Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 412.595px 223.335px;
          "
          id="el5mpxan9ztf5"
          class="animable"
        ></path>
        <circle
          cx="383.46"
          cy="132.54"
          r="30.11"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 383.46px 132.54px;
          "
          id="el6rrme8d5kf"
          class="animable"
        ></circle>
        <path
          d="M381.2,118.62c.29.65-1.52,2.09-4,3.22s-4.8,1.52-5.09.86,1.51-2.09,4-3.22S380.9,118,381.2,118.62Z"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 376.655px 120.669px;
          "
          id="elcavimiueug9"
          class="animable"
        ></path>
        <polygon
          points="394.75 146.55 385.74 150.91 377.66 134.27 372.08 122.77 381.09 118.4 386.53 129.62 394.75 146.55"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 383.415px 134.655px;
          "
          id="elte1vq84r57k"
          class="animable"
        ></polygon>
        <path
          d="M373.75,126.2s3.91-1.81,3.91-1.81"
          style="
            fill: #1b2cc1;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 375.705px 125.295px;
          "
          id="elgoa51c34gh4"
          class="animable"
        ></path>
        <path
          d="M375.45,129.82s3.91-1.81,3.91-1.81"
          style="
            fill: #1b2cc1;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 377.405px 128.915px;
          "
          id="eliugsrc0e1km"
          class="animable"
        ></path>
        <polygon
          points="389.19 149.24 393.84 155.34 391.52 148.11 389.19 149.24"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 391.515px 151.725px;
          "
          id="el8kj3jpkfhad"
          class="animable"
        ></polygon>
        <path
          d="M394.75,146.55h0a15,15,0,0,1-7.73,4.12l-1.28.24-8.08-16.64,8.87-4.65Z"
          style="
            fill: #1b2cc1;
            stroke: rgb(38, 50, 56);
            stroke-miterlimit: 10;
            transform-origin: 386.205px 140.265px;
          "
          id="eldap3axbomyi"
          class="animable"
        ></path>
        <line
          x1="379.36"
          y1="137.43"
          x2="383.04"
          y2="135.73"
          style="
            fill: #1b2cc1;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 381.2px 136.58px;
          "
          id="elgy1dj4jb5l7"
          class="animable"
        ></line>
        <path
          d="M381.38,141.3s3.61-1.65,3.61-1.65"
          style="
            fill: #1b2cc1;
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 383.185px 140.475px;
          "
          id="elev4h2gyr1wt"
          class="animable"
        ></path>
        <polygon
          points="375.2 121.25 372.35 114.84 374.59 113.57 377.66 120.06 375.2 121.25"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 375.005px 117.41px;
          "
          id="el23k22hb930n"
          class="animable"
        ></polygon>
        <path
          d="M380.09,112.79l-.68-1.5-12,5.37.68,1.5c.28.64,3.19-.06,6.51-1.54S380.37,113.42,380.09,112.79Z"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 373.761px 114.844px;
          "
          id="elghnk6bqgscf"
          class="animable"
        ></path>
        <path
          d="M379.41,111.29c-.28-.63-3.2.06-6.51,1.54s-5.77,3.2-5.49,3.83,3.2-.06,6.51-1.55S379.69,111.92,379.41,111.29Z"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 373.41px 113.975px;
          "
          id="elwg99zy038mo"
          class="animable"
        ></path>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#32DFEC"
            flood-opacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#ff0000"
            flood-opacity="0.5"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          ></feColorMatrix>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style>
svg#freepik_stories-medicine:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-medicine.animated #freepik--Graphics--inject-78 {
  animation: 0.7s 1 forwards ease-in fadeIn;
  animation-delay: 0s;
}
svg#freepik_stories-medicine.animated #freepik--Medicine--inject-78 {
  animation: 1s 1 forwards ease-in fadeIn;
  animation-delay: 0s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animator-hidden {
  display: none;
}
</style>
